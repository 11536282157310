import React from 'react';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
import './App.scss';

import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import Default from './pages/Default';
import Footer from './components/Footer';

global.apiEndpoint = "https://janloneymetalier.cdn.prismic.io/api/v2";

const App = () => {
  return (
    <div className="App">

      <div className="content">
        <Router>
          <Route exact path="/" component={Home}/>
          <Route exact path="/portfolio" component={Portfolio}/>
          <Route path="/portfolio/:slug" component={Portfolio}/>
          <Route path="/:type(pages|projects)/:uid" component={Default}/>
        </Router>
      </div>

      <Footer/>
    </div>
  );
}

export default App;
