import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';
import './Home.scss';

import Header from '../components/Header';
import Projects from '../components/Projects';

const Home = props => {
  const [page, setPage] = useState({});

  useEffect(() => {
    Prismic.getApi(global.apiEndpoint)
      .then(api => {
        return api.query(
          Prismic.Predicates.at('document.type', 'home_page')
        );
      }).then(response => {
        setPage(response.results[0]);
      }, error => {
        console.error(error);
      });
  }, []);

  return (
    <React.Fragment>
      <Header subnavType="tags"/>

      <div className="Home">
        {page.data ?
          <div>
            <div className="hero-image">
              {page.data.hero_image ?
                <img src={page.data.hero_image.url} alt={page.data.hero_image.alt}/>
              : null}
            </div>

            <Projects tag="Featured"/>

            <ul className="portfolio-filters">
              <li>
                <Link exact to="/portfolio">All</Link>
              </li>
              <li>
                <Link to="/portfolio/sculpture">Sculpture</Link>
              </li>
              <li>
                <Link to="/portfolio/jewelry">Jewelry</Link>
              </li>
              <li>
                <Link to="/portfolio/objects">Objects</Link>
              </li>
            </ul>

            {page.data.body.map((slice, index) =>
              <div key={index}>
                {slice.slice_type === 'callout_text' ?
                  <div className="callout-text">
                    {RichText.render(slice.primary.copy)}
                  </div> 
                : null}  
              </div>
            )}
          </div>
        : null}
      </div>
    </React.Fragment>
  );
}

export default Home;