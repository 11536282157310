import React from 'react';
import './Footer.scss';

import { ReactComponent as Facebook } from '../images/facebook_wht.svg';
import { ReactComponent as Instagram } from '../images/instagram_wht.svg';
import { ReactComponent as Linkedin } from '../images/linkedin_wht.svg';

const Footer = props => {
  return (
    <footer>
      <div className="copyright">
        &copy;{new Date().getFullYear()} Jan Loney / Metalier
      </div>

      <div className="social">
        <a href="https://www.facebook.com/jan.loney" target="_blank" rel="noopener noreferrer">
          <Facebook/>
        </a>

        <a href="https://www.instagram.com/janloney7/" target="_blank" rel="noopener noreferrer">
          <Instagram/>
        </a>

        <a href="https://www.linkedin.com/in/jan-loney-908511/" target="_blank" rel="noopener noreferrer">
          <Linkedin/>
        </a>
      </div>
    </footer>
  );
}

export default Footer;