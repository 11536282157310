import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Prismic from 'prismic-javascript';
import './Projects.scss';

const Projects = props => {
  const [projects, setProjects] = useState([]);
  const [pageConfig, setPageConfig] = useState({
    page: 1,
    total_pages: 1
  });

  useEffect(() => {
    if (props.tag) {
      document.querySelector('.spinner').classList.remove('hide');

      Prismic.getApi(global.apiEndpoint)
        .then(api => {
          return api.query(
            Prismic.Predicates.at('document.tags', [props.tag]),
            {
              pageSize: 12,
              orderings: '[my.project.uid]'
            }
          );
        }).then(response => {
          document.querySelector('.spinner').classList.add('hide');
          setPageConfig({ ...pageConfig, total_pages: response.total_pages });
          setProjects(response.results);
        }, error => {
          console.error(error);
        });
    }
  }, [props.tag])

  useEffect(() => {
    document.querySelector('.spinner').classList.remove('hide');

    if (!props.tag) {
      Prismic.getApi(global.apiEndpoint)
        .then(api => {
          return api.query(
            Prismic.Predicates.at('document.type', 'project'),
            {
              pageSize: 16,
              orderings: '[my.project.uid]'
            }
          );
        }).then(response => {
          document.querySelector('.spinner').classList.add('hide');
          setPageConfig({ ...pageConfig, total_pages: response.total_pages });
          setProjects(response.results);
        }, error => {
          console.error(error);
        });
    }
  }, []);

  const loadNextPage = () => {
    const newPage = pageConfig.page + 1;

    if (props.tag) {
      Prismic.getApi(global.apiEndpoint)
        .then(api => {
          return api.query(
            Prismic.Predicates.at('document.tags', [props.tag]),
            {
              pageSize: 12,
              page: newPage,
              orderings: '[my.project.uid]'
            }
          );
        }).then(response => {
          setPageConfig({ ...pageConfig, page: newPage });
          let updatedProjects = [...projects];
          updatedProjects = updatedProjects.concat(response.results);
          setProjects(updatedProjects);
        }, error => {
          console.error(error);
        });
    } else {
      Prismic.getApi(global.apiEndpoint)
        .then(api => {
          return api.query(
            Prismic.Predicates.at('document.type', 'project'),
            {
              pageSize: 16,
              page: newPage,
              orderings: '[my.project.uid]'
            }
          );
        }).then(response => {
          setPageConfig({ ...pageConfig, page: newPage });
          let updatedProjects = [...projects];
          updatedProjects = updatedProjects.concat(response.results);
          setProjects(updatedProjects);
        }, error => {
          console.error(error);
        });
    }
  }

  return (
    <div className="projects">
      <div className="projects__wrapper">
        <svg className="spinner" width="64px" height="64px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
          <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="32" cy="32" r="28"></circle>
        </svg>

        {projects.map(project =>
          project.tags.length ?
            <Link to={`/projects/${project.uid}`} className="project" key={project.id}>
              <div>
                {project.data.featured_image ?
                  <div className="project__image">
                    <img src={project.data.featured_image.url} alt={project.data.featured_image.alt}/>
                  </div>
                : null}

                <div className="project__info">
                  {project.data.project_type.length ?
                    <div className="project__tags">
                      {project.data.project_type[0].text}
                    </div>
                  : null}
                  <h3>{project.data.project_title[0].text}</h3>
                </div>
              </div>
            </Link>
          : null 
        )}

        {pageConfig.page !== pageConfig.total_pages && pageConfig.total_pages !== 0 ?
          <div className="projects__load-more">
            <button onClick={loadNextPage}>Load More</button>
          </div>
        : null}
      </div>
    </div>
  );
}

export default Projects;