import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';

import Header from '../components/Header';
import Projects from '../components/Projects';

const Portfolio = props => {
  const [portfolio, setPortfolio] = useState({});
  const [tag, setTag] = useState('');

  useEffect(() => {
    let projectTag = '';
    if (props.history.location.pathname.match(/\/portfolio\//)) {
      projectTag = props.history.location.pathname.replace(/\/portfolio\//, '');
      projectTag = projectTag.charAt(0).toUpperCase() + projectTag.slice(1);
    }

    setTag(projectTag);

    Prismic.getApi(global.apiEndpoint)
      .then(api => {
        return api.query(
          Prismic.Predicates.at('document.type', 'portfolio')
        );
      }).then(response => {
        setPortfolio(response.results[0]);
      }, error => {
        console.error(error);
      });
  }, [props.history.location]);

  return (
    <React.Fragment>
      <Header subnavType="tags"/>

      <ul className="portfolio-filters">
        <li>
          <NavLink exact to="/portfolio">All</NavLink>
        </li>
        <li>
          <NavLink to="/portfolio/sculpture">Sculpture</NavLink>
        </li>
        <li>
          <NavLink to="/portfolio/jewelry">Jewelry</NavLink>
        </li>
        <li>
          <NavLink to="/portfolio/objects">Objects</NavLink>
        </li>
      </ul>

      {portfolio.data ?
        <div>
          <Projects tag={tag}/>

          {portfolio.data.body.map((slice, index) =>
            <div key={index}>
              {slice.slice_type === 'callout_text' ?
                <div className="callout-text">
                  {RichText.render(slice.primary.copy)}
                </div> 
              : null}  
            </div>
          )}
        </div>
      : null}
    </React.Fragment>
  );
}

export default Portfolio;