import React from 'react';
import { RichText } from 'prismic-reactjs';
const Elements = RichText.Elements;

export const LinkResolver = (doc) => {
  if (doc.type === 'default') return `/pages/${doc.uid}`;
  if (doc.type === 'projects') return `/projects/${doc.uid}`;

  return `/${doc.uid}`;
}

const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key });
};

export const HtmlSerializer = (type, element, content, children, key) => {
  let props = {};

  switch(type) {
    case Elements.image:
      props = { src: element.url, alt: element.alt || '' };
      return (
        <span className="captioned-image" key={`image-${Math.random(4)}`}>
          {React.createElement('img', propsWithUniqueKey(props, key))}
          {props.alt ?
            <p>{props.alt}</p>
          : null}
        </span>
      );
    default:
      return null;
  }
}