import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import { LinkResolver, HtmlSerializer } from '../RichTextOverrides';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './Default.scss';

import Header from '../components/Header';

configureAnchors({ offset: -72, scrollDuration: 700 });

const Default = props => {
  const [type, setType] = useState('default');
  const [page, setPage] = useState({});
  const [url, setUrl] = useState('');
  const [anchors, setAnchors] = useState([]);
  const [links, setLinks] = useState({});

  useEffect(() => {
    if (props.history.location.pathname) {
      if (url !== props.history.location.pathname) {
        setUrl(props.history.location.pathname);
        setPage({});

        let template = props.match.params.type;
        if (template === 'pages') {
          template = 'default';
        } else if (template === 'projects') {
          template = 'project';
        }

        setType(template);

        Prismic.getApi(global.apiEndpoint)
          .then(api => {
            api.getByUID(template, props.match.params.uid)
              .then(response => {
                setPage(response);

                const loadedAnchors = [];
                response.data.body.forEach(slice => {
                  if (slice.slice_type === 'section_copy' && slice.primary.section_heading.length) {
                    const anchor = slice.primary.section_heading[0].text.replace(/\s/g, '');
                    loadedAnchors.push({ text: slice.primary.section_heading[0].text, anchor });
                  }
                });

                setAnchors(loadedAnchors);
              }, error => {
                console.error(error);
              });
          });
      }
    }
  }, [props.history.location]);

  useEffect(() => {
    if (page && Object.keys(page).length) {
      const linkIds = [];

      page.data.body.forEach(slice => {
        slice.items.forEach(link => {
          if (link.internal_link) {
            linkIds.push(link.internal_link.id);
          }
        });
      });

      Prismic.getApi(global.apiEndpoint)
        .then(api => {
          return api.query(
            Prismic.Predicates.in('document.id', linkIds),
            { pageSize: 100 }
          );
        }).then(response => {
          const currentLinks = { ...links };

          response.results.forEach(link => {
            currentLinks[`link${link.id}`] = link;
          });

          setLinks(currentLinks);
        }, error => {
          console.error(error);
        });
    }
  }, [page]);

  return (
    <React.Fragment>
      {Object.keys(page).length ?
        <React.Fragment>
          <Header subnavType={page.type === 'project' ? 'tags' : ''} anchors={anchors} page={page}/>

          <div className="default-content">
            {page.data.featured_image ?
              <img className="featured-image" src={page.data.featured_image.url} alt={page.data.featured_image.alt}/>
            : null}

            {page.data.project_type ?
              page.data.project_type.length ?
                <div className="project__tags">
                  {page.data.project_type[0].text}
                </div>
              : null
            : null}

            {type === 'project' ?
              <h1 className="project-title">
                {page.data.project_title[0].text}
              </h1>
            : type === 'default' ?
              <h1>{page.data.page_title[0].text}</h1>
            : null}

            {page.data.body.map((slice, index) =>
              <React.Fragment key={index}>
                {slice.slice_type === 'copy' ?
                  <div className="copy">
                    {RichText.render(slice.primary.copy, LinkResolver, HtmlSerializer)}
                  </div> 
                : slice.slice_type === 'section_copy' ?
                  <div className="copy">
                    {slice.primary.section_heading.length ?
                      <React.Fragment>
                        <ScrollableAnchor id={slice.primary.section_heading[0].text.replace(/\s/g, '')}>
                          <h2>{slice.primary.section_heading[0].text}</h2>
                        </ScrollableAnchor>
                      </React.Fragment>
                    : null}
                    {RichText.render(slice.primary.copy, LinkResolver, HtmlSerializer)}
                  </div>
                : slice.slice_type === 'callout_text' ?
                  <div className="callout-text">
                    {RichText.render(slice.primary.copy)}
                  </div>
                : slice.slice_type === 'link_grid' ?
                  <React.Fragment>
                    {slice.items.length ?
                      <div className="projects">
                        <div className="projects__wrapper">
                          {slice.items.map((link, index) =>
                            <React.Fragment key={index}>
                              {link.internal_link.type === 'project' ?
                                links[`link${link.internal_link.id}`] ?
                                  <Link to={`/projects/${link.internal_link.uid}`} className="project">
                                    <div>
                                      {links[`link${link.internal_link.id}`].data.featured_image ?
                                        <div className="project__image">
                                          <img
                                            src={links[`link${link.internal_link.id}`].data.featured_image.url}
                                            alt={links[`link${link.internal_link.id}`].data.featured_image.alt}
                                          />
                                        </div>
                                      : null}

                                      <div className="project__info">
                                        {links[`link${link.internal_link.id}`].data.project_type.length ?
                                          <div className="project__tags">
                                            {links[`link${link.internal_link.id}`].data.project_type[0].text}
                                          </div>
                                        : null}
                                        <h3>{links[`link${link.internal_link.id}`].data.project_title[0].text}</h3>
                                      </div>
                                    </div>
                                  </Link>
                                : null
                              : null}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    : null}
                  </React.Fragment>
                : slice.slice_type === 'gallery' ?
                  <React.Fragment>
                    <Carousel
                      showStatus={false}
                      showThumbs={false}
                      infiniteLoop={true}
                      swipeable={true}
                      emulateTouch={true}
                      dynamicHeight={true}
                    >
                      {slice.items.map((item, index) =>
                        <div key={index}>
                          <img src={item.image.url} alt={item.caption.length ? item.caption[0].text : ''}/>
                          {item.caption.length ?
                            <p className="legend">{item.caption[0].text}</p>
                          : null}
                        </div>
                      )}
                    </Carousel>
                  </React.Fragment>
                : slice.slice_type === 'horizontal_rule' ?
                  <hr/>
                : null}  
              </React.Fragment>
            )}

            {page.data.parent_page.uid ?
              <Link
                className="back-button"
                to={page.data.parent_page.type === 'default' ? `/pages/${page.data.parent_page.uid}` : `/projects/${page.data.parent_page.uid}`}
              >
                {'< Back'}
              </Link>
            : null}
          </div>
        </React.Fragment>
      : null}
    </React.Fragment>
  );
}

export default Default;