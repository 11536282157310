import React, { useEffect, useState } from 'react';
import Prismic from 'prismic-javascript';
import {
  NavLink,
  Link,
  withRouter
} from 'react-router-dom';
import { ReactComponent as Logo } from '../images/Jan_Loney_BOX.svg';
import { ReactComponent as SmallLogo } from '../images/Jan_Loney.svg';
import './Header.scss';

import { ReactComponent as Facebook } from '../images/facebook_wht.svg';
import { ReactComponent as Instagram } from '../images/instagram_wht.svg';
import { ReactComponent as Linkedin } from '../images/linkedin_wht.svg';

const Header = props => {
  const [menu, setMenu] = useState([]);
  const [open, setOpen] = useState(false);
  const [slideActive, setSlideActive] = useState(false);

  window.addEventListener('scroll', (e) => {
    if (window.scrollY > 360 && !slideActive) {
      setSlideActive(true);
    } else if (window.scrollY <= 360 && slideActive) {
      setSlideActive(false);
    }
  });

  useEffect(() => {
    if (props.history.location) {
      setOpen(false);
    }
  }, [props.history.location]);

  useEffect(() => {
    Prismic.getApi(global.apiEndpoint)
      .then(api => {
        return api.query(
          Prismic.Predicates.at('my.main_menu.uid', 'main-menu')
        )
      }).then(response => {
        const mainMenu = response.results[0].data.body;
        const subnavIds = [];

        mainMenu.forEach(item => {
          if (item.slice_type === 'internal_link' && item.primary.page_url) {
            subnavIds.push(item.primary.page_url.id);
          }
        });

        Prismic.getApi(global.apiEndpoint)
          .then(api => {
            return api.query(
              Prismic.Predicates.in('document.id', subnavIds),
              { pageSize: 50 }
            );
          }).then(response => {
            response.results.forEach(page => {
              const menuItem = mainMenu.find(item => item.primary.page_url.id === page.id);

              if (page.type === 'portfolio') {
                menuItem.subnav = [
                  { text: 'All', link: '/portfolio' },
                  { text: 'Sculpture', link: '/portfolio/sculpture' },
                  { text: 'Jewelry', link: '/portfolio/jewelry' }, 
                  { text: 'Objects', link: '/portfolio/objects '}
                ];
              } else if (page.type === 'default') {
                const loadedAnchors = [];
                page.data.body.forEach(slice => {
                  if (slice.slice_type === 'section_copy') {
                    if (slice.primary.section_heading.length) {
                      const anchor = slice.primary.section_heading[0].text.replace(/\s/g, '');
                      loadedAnchors.push({ text: slice.primary.section_heading[0].text, anchor });
                    }
                  }
                });

                menuItem.subnav = loadedAnchors;
              }
            });

            setMenu(mainMenu);
          }, error => {
            console.error(error);
          })
      }, error => {
        console.error(error);
      });
  }, []);

  const toggleMenu = () => {
    setOpen(!open);
  }

  const handleMouseOver = event => {
    document.querySelectorAll('.subnav').forEach(nav => {
      nav.classList.remove('over');
    });

    const subnav = event.currentTarget.nextElementSibling;
    if (subnav) {
      subnav.classList.add('over');
    }    
  }

  const handleDropdown = event => {
    const target = event.currentTarget;

    target.classList.toggle('open');
    toggleSlide(target.previousElementSibling);
  }

  const getHeight = (el) => {
    var el_style      = window.getComputedStyle(el),
        el_display    = el_style.display,
        el_position   = el_style.position,
        el_visibility = el_style.visibility,
        el_max_height = el_style.maxHeight.replace('px', '').replace('%', ''),

        wanted_height = 0;


    // if its not hidden we just return normal height
    if (el_display !== 'none' && el_max_height !== '0') {
        return el.offsetHeight;
    }

    // the element is hidden so:
    // making the el block so we can meassure its height but still be hidden
    el.style.position   = 'absolute';
    el.style.visibility = 'hidden';
    el.style.display    = 'block';

    wanted_height     = el.offsetHeight;

    // reverting to the original values
    el.style.display    = el_display;
    el.style.position   = el_position;
    el.style.visibility = el_visibility;

    return wanted_height;
  };

  const toggleSlide = (el) => {
    var el_max_height = 0;

    if (el.getAttribute('data-max-height')) {
        // we've already used this before, so everything is setup
        if (el.style.maxHeight.replace('px', '').replace('%', '') === '0') {
            el.style.maxHeight = el.getAttribute('data-max-height');
        } else {
            el.style.maxHeight = '0';
        }
    } else {
        el_max_height                  = getHeight(el) + 'px';
        el.style['transition']         = 'max-height 0.5s ease-in-out';
        el.style.overflowY             = 'hidden';
        el.style.maxHeight             = '0';
        el.setAttribute('data-max-height', el_max_height);
        el.style.display               = 'block';

        // we use setTimeout to modify maxHeight later than display (to we have the transition effect)
        setTimeout(function() {
            el.style.maxHeight = el_max_height;
        }, 10);
    }
  }

  return (
    <React.Fragment>
      <header className={`floating-header${slideActive ? ' slide-down' : ''}`}>
        <Link to="/">
          <SmallLogo/>
        </Link>

        <button className={`menu-toggle${open ? ' open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </header>

      <header className="header">
        <Link to="/" className="header__logo">
          <Logo/>
        </Link>

        <div className="header__shortcuts">
          {props.page && props.page.data.parent_page.uid ?
            <Link
              to={props.page.data.parent_page.type === 'default' ? `/pages/${props.page.data.parent_page.uid}` : `/projects/${props.page.data.parent_page.uid}`}
            >
              {'< Back'}
            </Link>
          : props.subnavType === 'tags' && ((props.page && props.page.tags.length) || !props.page) ?
            <ul>
              <li>
                <NavLink exact to="/portfolio">All</NavLink>
              </li>
              <li>
                <NavLink to="/portfolio/sculpture">Sculpture</NavLink>
              </li>
              <li>
                <NavLink to="/portfolio/jewelry">Jewelry</NavLink>
              </li>
              <li>
                <NavLink to="/portfolio/objects">Objects</NavLink>
              </li>
            </ul>
          : props.anchors ?
            props.anchors.length ?
              <ul>
                {props.anchors.map(item =>
                  <li key={item.anchor}>
                    <a href={`#${item.anchor}`}>{item.text}</a>
                  </li>  
                )}
              </ul>
            : null
          : null}
        </div>

        <button className={`menu-toggle${open ? ' open' : ''}`} onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </button>

        <div className={`main-menu${open ? ' main-menu--open' : ''}`}>
          <Link to="/" className="header__logo">
            <Logo/>
          </Link>

          <button className={`menu-toggle${open ? ' open' : ''}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </button>

          <ul>
            {menu.map((item, index) => 
              <li key={index}>
                {item.primary.page_url ?
                  <NavLink 
                    to={`${item.primary.page_url.type === 'default' ? '/pages' : item.primary.page_url.type === 'portfolio' ? '' : '/projects'}/${item.primary.page_url.slug}`}
                    onMouseEnter={handleMouseOver}
                  >
                    {item.primary.link_text}
                  </NavLink>
                : item.primary.website_url ?
                  item.primary.website_url.link_type === 'Document' ?
                  <NavLink
                    to={`${item.primary.website_url.type === 'default' ? '/pages' : item.primary.website_url.type === 'portfolio' ? '' : '/projects'}/${item.primary.website_url.slug}`}
                    onMouseEnter={handleMouseOver}
                  >
                    {item.primary.link_text}
                  </NavLink>
                  : item.primary.website_url.link_type === 'Media' ?
                    <a
                      href={item.primary.website_url.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={handleMouseOver}
                    >
                      {item.primary.link_text}
                    </a>
                  :
                    <a
                      href={item.primary.website_url.url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      onMouseEnter={handleMouseOver}
                    >
                      {item.primary.link_text}
                    </a>
                : null}

                {item.subnav ?
                  item.subnav.length ?
                    <ul className="subnav">
                      {item.subnav.map((child, index) =>
                        <li key={index}>
                          {child.anchor ?
                            <Link to={`${item.primary.page_url.type === 'default' ? '/pages' : item.primary.page_url.type === 'portfolio' ? '' : '/projects'}/${item.primary.page_url.slug}#${child.anchor}`}>
                              {child.text}
                            </Link>
                          :
                            <Link to={child.link}>
                              {child.text}
                            </Link>
                          }
                        </li>
                      )}
                    </ul>
                  : null
                : null}

                {item.subnav ?
                  item.subnav.length ?
                    <span
                      className="child-toggle"
                      onClick={handleDropdown}
                    ></span>
                  : null
                : null}
              </li>
            )}
          </ul>

          <div className="social">
            <a href="https://www.facebook.com/jan.loney" target="_blank" rel="noopener noreferrer">
              <Facebook/>
            </a>

            <a href="https://www.instagram.com/janloney7/" target="_blank" rel="noopener noreferrer">
              <Instagram/>
            </a>

            <a href="https://www.linkedin.com/in/jan-loney-908511/" target="_blank" rel="noopener noreferrer">
              <Linkedin/>
            </a>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default withRouter(Header);